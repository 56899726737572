import React, { Component } from "react";
import { connect } from "react-redux";
import { getSurvey, setSurveyType, addResponse, updateResponse, loadOffline, setLocalSurveys,getLocalSurvey, loadOnline,setStages,loadSurvey, setUserID } from "../actions/index";
import Slider from "react-slick";
import Questions from "./questions";
import Menu from "./menu";
import Header from "./header"
import _ from 'underscore';

import "../css/slick.min.css";
import "../css/slick-theme.min.css";
import "../css/slide_styles.css";
import ReactMarkdown from "react-markdown"
import { Field, reduxForm,reset, validate } from 'redux-form';
import QueryString from 'query-string'

export class IntroExternal extends Component {
  state = {
    activeSlide: 0,
    activeSlide2: 0,
    MenuOpen:false,
        background:true

  };

  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.ToggleMenu = this.ToggleMenu.bind(this);


  }

  componentDidMount() {
    const UserID = QueryString.parse(window.location.search);
    this.props.setSurveyType("external");
    if(UserID.id !== undefined){

      //get pardot details
      //load previous form
    }

    if(UserID.userID !== undefined){
              this.props.setUserID(UserID.userID)
    }

    if(UserID.surveyID !== undefined){
      //get previous answers
      this.props.loadSurvey(UserID.surveyID)
    }
  }
  componentDidUpdate() {
    if(this.props.survey === undefined){
    this.props.getSurvey();
  }
  if(this.props.surveyID !== ""){
    this.props.loadOffline()
    this.props.getLocalSurvey();

  }

  }


  ToggleMenu(){
    if(this.state.MenuOpen){
          this.setState({ MenuOpen: false })
    }else{
          this.setState({ MenuOpen: true })
    }



  }

  play() {
    this.slider.slickPlay();

  }
  pause() {
    this.slider.slickPause();

  }
  next(){
    this.slider.slickNext()
    let stages = [];
    if(this.props.answers.values.Your_Role !== undefined){
        if(this.props.answers.values.Your_Role.What_stages_did_you_evaluate !== undefined){
          for (let [key, value] of Object.entries(this.props.answers.values.Your_Role.What_stages_did_you_evaluate)){
            if(value === true){
              stages.push(key)
            }
          }
    this.props.setStages(stages)
         }
        }

        if(this.props.answers.values !== undefined){
                //save locally
                localStorage.setItem(this.props.surveyID, JSON.stringify(this.props.answers.values));
            }

            var scrollStep = -window.scrollY / (20),
                  scrollInterval = setInterval(function(){
                  if ( window.scrollY != 0 ) {
                      window.scrollBy( 0, scrollStep );
                  }
                  else clearInterval(scrollInterval);
              },15);
  }

  previous(){
    this.slider.slickPrev()
    if(this.props.answers.values !== undefined){
            //save locally
            localStorage.setItem(this.props.surveyID, JSON.stringify(this.props.answers.values));
        }

        var scrollStep = -window.scrollY / (20),
              scrollInterval = setInterval(function(){
              if ( window.scrollY != 0 ) {
                  window.scrollBy( 0, scrollStep );
              }
              else clearInterval(scrollInterval);
          },15);
  }



  submit(formProps, dispatch) {
    console.log(formProps)
    this.props.addResponse(this.props.answers.values, true)
          localStorage.removeItem(this.props.surveyID)
          dispatch(reset('bid'));
          this.slider.slickGoTo(0)
  }







  GetSectionTitle(el){
    if(this.props.surveyType === "external"){
      return{__html:el.section_name}
    }else{
      if( el.External_section_name === undefined){
        return{__html: el.section_name}
      }else{
        return{__html: el.External_section_name}
      }
    }



  }


  render() {

    let DisplaySections = []
    for(let section of this.props.sections){
      let StageName = ""

      if(section.stage !== undefined){
        if(Array.isArray(section.stage) ){
          if(section.stage.length > 0){
            for(let stage of section.stage){
                for(let bidstage of this.props.BidStages){
                  StageName = stage.replace(" ","").replace("/","")
                  console.log(StageName, bidstage)
                  if(StageName === bidstage){
                    DisplaySections.push(section)
                  }
                }
            }
          }
          else{
                      DisplaySections.push(section)
              }
        }

      }

}








        const { handleSubmit } = this.props;
        let activeSlide =false
        //

        if(this.state.activeSlide2 === this.state.activeSlide){
        activeSlide =true
        }

        const settings = {
          dots: true,
          draggable: false,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          fade:false,
                    adaptiveHeight:true,
                    beforeChange: (current, next) => {
                             console.log("CHANGE")
                             if (current === 0) {
                               this.setState({ background: false });
                             }
                                  this.setState({activeSlide:next})
                         }
                  };

        let ActiveSlide = this.state.activeSlide2

    if(this.props.ready){
      let cardStyle = {
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),url(${this.props.strapiRoot + this.props.survey[0].image.url})`
      };
    return (
      <>
      <div className="page-wrap">
        <div className={this.state.background ? "bgImage":"hide"} style={cardStyle}></div>
      <Header ToggleMenu={this.ToggleMenu} />
      <div className={this.props.surveyType === "internal" ? "":"hide"}>
      </div>
      <form onSubmit={handleSubmit(this.submit.bind(this))} >
      <Slider ref={slider => (this.slider = slider)} {...settings}>

  <div key="0" className="FOI_slide" >

    <div className="slideinner" >

    <div className="all-80 small-100 tiny-100 push-center content top-space">
    <div className="push-center all-100">
    <div className="grey-text-box">
      <h1>{this.props.survey[0].name}</h1>
      <div>
    {this.props.surveyType === "internal" ?
      ( <ReactMarkdown source={this.props.survey[0].intro_internal} />):(<ReactMarkdown source={this.props.survey[0].intro} />)
    }
</div>

  </div>
  </div>
</div>

</div>
</div>
        {DisplaySections.map((el, index) => (

        <div key={index} className="FOI_slide">
          <div className="slideinner">
          <div className="column-group header all-100">
          <div className="column-group all-100">
          <h2 dangerouslySetInnerHTML={this.GetSectionTitle(el)}/>
          </div>
          <div className="column-group all-100">
          {this.props.surveyType === "external" ?
            ( <div className="section_info"><ReactMarkdown source={el.section_info} /></div>):(<span></span>)
          }
          </div>
          </div>
          <Questions questions={el} index={index} updateHeight={this.updateHeight}/>


          </div>
        </div>



      ))}
      <div key="end" className="FOI_slide">
        <div className="slideinner">
        <div className="all-80 small-100 tiny-100 push-center content top-space">
        <div className="push-center all-100">
        <div className="grey-text-box">
      <h1>Thank you</h1>
      <div>
      {this.props.surveyType === "internal" ?
        ( <ReactMarkdown source={this.props.survey[0].thankyou_internal} />):(<ReactMarkdown source={this.props.survey[0].thankyou} />)
      }
      {this.props.submitFailed ?
        ( <div>There are some errors in your form, please correct</div>):(<span></span>)
      }
      <button className="buttonOrange">Submit completed survey</button>
      </div>
      </div>
      </div>
      </div>

      </div>
      </div>



</Slider>
</form>
</div>
<footer>

<div>
<button  className={this.state.activeSlide === 0 ? 'hide' : 'button left'} onClick={this.previous}>
  Previous
</button>
<div className="slick-dots"/>
  <button className="button right" onClick={this.next}>
    Next
  </button>

</div>
</footer>

</>
    );
  }else{

    if(this.props.surveyID === ""){
      return (
  <div>Sorry the link you have is wrong. <br/>Please copy the full survey address including the survey id into your browser</div>
      )
    }else{
    return (
<div>LOADING</div>
    )
  }

  }
  }
}
function mapStateToProps(state) {
  return {
    survey: state.data.survey,
    sections: state.data.sections,
    PerformQuestions:state.data.PerformQuestions,
    ready:state.data.ready,
    strapiRoot:state.data.strapiRoot,
    surveyType:state.data.surveyType,
    answers: state.form.bid,
    responseID:state.data.ResponseID,
    initialValues:state.data.initalValues,
    BidStages:state.data.BidStages,
    submitFailed: state.form.submitFailed,
    surveyID:state.data.surveyID



  };
}


export default connect(
  mapStateToProps,
  { getSurvey, setSurveyType, addResponse, updateResponse,setLocalSurveys,getLocalSurvey, loadOffline, loadOnline,setStages,loadSurvey, setUserID  }
)(reduxForm({
   form: 'bid', // a unique identifier for this form
  enableReinitialize: true
})(IntroExternal))

//
// Intro = connect(
//   mapStateToProps,
//   { getSurvey, setSurveyType, addResponse, updateResponse,setLocalSurveys,getLocalSurvey, loadOffline  }
// )(Intro)
//
//
// export default reduxForm({
//     form: 'bid',
//   initialValues:
// })(Intro);
