import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../css/AECOM_logo_white.svg"
import close from "../css/close.svg"

import MenuItem from "./menu_item";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from '../components/Button';
import auth from '../utils/auth';






export class Menu extends Component {

  constructor(props) {
    super(props);

  }

  componentDidMount(){

  }


  render() {
  console.log(this.props.history)

if(this.props.localSurveys === null){
  return (
<nav className={this.props.MenuOpen ? "Menu OpenMenu" : "Menu "}>
<img className="menuClose" src={close} onClick={this.props.ToggleMenu}/>
<ul>
<h2>No draft surveys</h2>

</ul>

</nav>
    );
}else{
  return (
<nav className={this.props.MenuOpen ? "Menu OpenMenu" : "Menu "}>
<img className="menuClose" src={close} onClick={this.props.ToggleMenu}/>
<ul className="savedSurveys">
<h2>My draft surveys</h2>
{this.props.localSurveys.map((el, index) => (
<MenuItem key={index} Survey={el} ToggleMenu={this.props.ToggleMenu}/>
))}
</ul>
<ul className="Menuactions">
<li onClick={() => {
  this.props.history.push('/aecom/survey/view_my') }}>View my completed surveys</li>
<li onClick={() => {
  this.props.history.push('/aecom/survey/view_all') }}>View all completed surveys</li>
<li onClick={this.props.sendSurvey}>Send client survey</li>
<li><a href={this.props.survey[0].dashboard_link} target="_blank">Results dashboard</a></li>


<li onClick={() => {
  auth.clearAppStorage();
  this.props.history.push('/aecom?surveyID='+this.props.survey[0]._id);
}}>Logout</li>
</ul>
</nav>
    );
}





  }
}

Menu.propTypes = {
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    survey: state.data.survey,
    localSurveys:state.data.localSurveys
  };
}
export default connect(
  mapStateToProps
)(Menu);
