import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../css/AECOM_logo_white.svg"
import close from "../icons_hamburger.svg"
import home from "../css/Home.svg"
import { Link } from 'react-router-dom';




export class Header extends Component {

  constructor(props) {
    super(props);

  }


  render() {
let Name = "Choose Survey"
console.log(this.props.survey)
if(this.props.survey.length < 0){
  Name = this.props.survey[0].name
}
if(this.props.surveyType === "internal" ){
  return (

<nav>

<Link to='/aecom'><img className="title" src={home}/></Link>
      <Link to='/aecom'><img className="logo" src={logo}/></Link>
<img className="MenuOpen"  src={close} onClick={this.props.ToggleMenu}/>
</nav>
  );
}else{
  return (

<nav>

  <img className="logo" src={logo}/>

</nav>
  );

}


  }
}

function mapStateToProps(state) {
  return {
    survey: state.data.survey,
    surveyType:state.data.surveyType,
  };
}
export default connect(
  mapStateToProps
)(Header);
