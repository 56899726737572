import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../css/AECOM_logo_white.svg"
import close from "../icons_hamburger.svg"
import { getAllSurveys, loadSurvey, setUserID } from "../actions/index";
import { withRouter } from 'react-router-dom';
import QueryString from 'query-string'




export class ChooseSurvey extends Component {

  constructor(props) {
    super(props);

  }

  componentDidMount(){
    this.props.getAllSurveys()

    const UserID = QueryString.parse(this.props.location.search);
    if(UserID.surveyID !== undefined){
      //get previous answers
      console.log("LOAD CHOOSE")
      this.props.loadSurvey(UserID.surveyID)
    }
    if(sessionStorage.getItem('userInfo') !== null){
              this.props.setUserID(JSON.parse(sessionStorage.getItem('userInfo'))._id)
    }

  }


  redirectSurvey(link){

      this.props.loadSurvey(link)

  }


  render() {
    console.log(this.props.history)
    return (
      <div key="end" className="FOI_slide">
        <div className="slideinner">
              <div className="ink-grid">
              <div className="column-group">
              <div className="all-100">
        <h2>You have logged in without choosing a survey</h2>
        </div>
          <div className="all-100">
        <p>Please choose one of the surveys below</p>
        </div>
        </div>
              <div className="column-group top-space gutters">
{this.props.AllSurveys.map((el, index) => (
<div key={index} onClick={() => this.redirectSurvey(el._id)} className="all-30 tiny-100 small-100" >
<div style={{
  width:"100%",
  height:"100%",
  minHeight:"300px",
    background:"linear-gradient(rgba(50, 62, 72, 0.6), rgba(50, 62, 72, 0.6)), url(" + this.props.strapiRoot + el.image.url + ")",
    backgroundSize: "cover",
    WebkitBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    OBackgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    zIndex:"1",
    position:"relative"
  }}>
<h4 className="surveyTitle">{el.name}</h4>
</div>
</div>
))}
</div>
</div>
              </div>
              </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    AllSurveys: state.data.AllSurveys,
        strapiRoot:state.data.strapiRoot,
  };
}
export default withRouter(connect(
  mapStateToProps,
  {getAllSurveys, loadSurvey, setUserID}
)(ChooseSurvey));
