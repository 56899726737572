import React, { Component } from "react";
import { connect } from "react-redux";
import GeneralQuestion from "./genquestions.js"
import PerformanceQuestion from "./performancequestions.js"
import Slider_Controller from "./slider_controler.js"
import { Field} from 'redux-form'
import _ from 'underscore';

export class Questions extends Component {

  constructor(props) {
    super(props);

  }




  render() {

console.log(this.props.questions)
    return(

        <>
        {this.props.questions.map((el, index) =>  {
          let StageName = ""
          if(el.stages[0] !== undefined){
            StageName = el.stages[0].stage_name.replace(" ","").replace("/","")
          }
          return this.props.BidStages.includes(StageName)||el.stages.length === 0 ?

            <div key={index} className="column-group gutters question questionGroup">

              <Field
                type="text"
                name={el.section.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+el.question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+".value"}
                component={Slider_Controller}
                question={el}
                index={index}
                sectionindex={this.props.index}
              />
              </div>

:<div></div>
}


      )}
        </>
      )




  }
}

function mapStateToProps(state) {
  return {
    survey: state.data.survey,
    sections:state.data.sections,
    BidStages:state.data.BidStages,
        surveyType:state.data.surveyType
  };
}
export default connect(
  mapStateToProps
)(Questions);
