export const DATA_LOADED = "DATA_LOADED";
export const DATA_ERROR = "DATA_ERROR";
export const DATA_LOADING = "DATA_LOADING";
export const SECTIONS_LOADING = "SECTIONS_LOADING";
export const SECTIONS_LOADED = "SECTIONS_LOADED";
export const SECTIONS_READY = "SECTIONS_READY";
export const GENERAL_LOADING = "GENERAL_LOADING";
export const GENERAL_LOADED = "GENERAL_LOADED";
export const GENERAL_READY = "GENERAL_READY";
export const PERFORMANCE_LOADING = "PERFORMANCE_LOADING";
export const PERFORMANCE_LOADED = "PERFORMANCE_LOADED";
export const PERFORMANCE_READY = "PERFORMANCE_READY";
export const SURVEY_READY = "SURVEY_READY";
export const SET_SURVEY_TYPE = "SET_SURVEY_TYPE";
export const RESPONSE_UPDATING = "RESPONSE_UPDATING";
export const RESPONSE_UPDATED = "RESPONSE_UPDATED";
export const RESPONSE_ERROR = "RESPONSE_ERROR";
export const RESPONSE_CREATED = "RESPONSE_CREATED";
export const FORM_LOADED = "FORM_LOADED";
export const LOCAL_SURVEYS = "LOCAL_SURVEYS";
export const LOAD_LOCAL_SURVEYS = "LOAD_LOCAL_SURVEYS";
export const LOADED_SURVEY = "LOADED_SURVEY";
export const FORM_LOADING = "FORM_LOADING";
export const FORM_ERROR = "FORM_ERROR";
export const SET_STAGES = "SET_STAGES";
export const STAGE_READY = "STAGE_READY";
export const STAGE_ERROR = "STAGE_ERROR";
export const SET_SURVEYID = "SET_SURVEYID";
export const SET_USER = "SET_USER";
export const ALL_SURVEYS = "ALL_SURVEYS";
export const RESULTS_LOADED = "RESULTS_LOADED";
