import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm  } from 'redux-form'

  const required = value => value ? undefined : 'Required'

  const renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
  <div>
    <label>{label}</label>
    <div>
      <input {...input} placeholder={label} type={type}/>
      {touched && ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  </div>
)

export class CheckQType extends Component {

  constructor(props) {
    super(props);

  }





  render() {




if(this.props.question.type === "text"){
    if(this.props.question.required){
      return(
        <Field name={this.props.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+this.props.question.Gen_Question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')} component={renderField} type="text" validate={[ required ]}/>
      )
    }else{
      return(
        <Field name={this.props.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+this.props.question.Gen_Question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')} component={renderField} type="text" />
      )
    }
        return(
          <Field name={this.props.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+this.props.question.Gen_Question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')} component={renderField} type="text" validate={[ required ]}/>
        )
      }else{

        if(this.props.question.evaulationcriteras.length >0){
          let Checks=""

              if(this.props.question.type=== "check_box"){
                return(
                  <div>
                  {this.props.question.stages.map((el, index) => (
                    <div key={index} className="check">
                      <Field name={this.props.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+this.props.question.Gen_Question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+ el.criteria.replace(/ /g, '_').replace(/[^\w\s]/gi, '')} id={this.props.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+this.props.question.Gen_Question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+ el.criteria.replace(/ /g, '_').replace(/[^\w\s]/gi, '')} value={el.criteria} component="input" type="checkbox"/>
                    <label htmlFor={this.props.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+this.props.question.Gen_Question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+ el.criteria.replace(/ /g, '_').replace(/[^\w\s]/gi, '')}>{el.criteria}</label>
                    </div>
                  ))}
                      </div>
                )


              }else{
                return(
                  <div>
                    <div className="check"></div>
                  {this.props.question.evaulationcriteras.map((el, index) => (
                    <Field key={index} name={this.props.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+this.props.question.Gen_Question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+ el.criteria.replace(/ /g, '_').replace(/[^\w\s]/gi, '')} component="textarea" placeholder={el.criteria} rows="3" cols ="50"/>

                  ))}
                    </div>
                )
              }



        }else if(this.props.question.stages.length >0){
            if(this.props.question.type=== "check_box"){
              return(
                <div>
                {this.props.question.stages.map((el, index) => (
                  <div key={index} className="check">
                    <Field name={this.props.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+this.props.question.Gen_Question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+ el.stage_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')} id={this.props.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+this.props.question.Gen_Question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+ el.stage_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')} value={el.stage_name} component="input" type="checkbox"/>
                  <label htmlFor={this.props.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+this.props.question.Gen_Question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+ el.stage_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')}>{el.stage_name}</label>
                  </div>
                ))}
                    </div>
              )
            }else{
              return(
                <div>
                  <div className="check"></div>
                {this.props.question.stages.map((el, index) => (
                  <Field key={index} name={this.props.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+this.props.question.Gen_Question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+ el.stage_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')} component="textarea" placeholder={el.stage_name} rows="3" cols ="50"/>

                ))}
                  </div>
              )
            }

      }else if(this.props.question.type=== "drop_down"){
        return(
          <div>
            <div className="check"></div>
            <Field name={this.props.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+this.props.question.Gen_Question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')} component="select">
           <option >Please select</option>
           {this.props.question.drop_down_values.split(",").map((el, index) => (
           <option value={el}>{el}</option>
         ))}
         </Field>
            </div>
        )



    }else{
        return(
          <div>
          <div className="check"></div>
          <Field name={this.props.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')+"."+this.props.question.Gen_Question.replace(/ /g, '_').replace(/[^\w\s]/gi, '')} component="textarea" placeholder="Your Response" rows="6" cols ="50"/>
</div>
        )
      }
      }

  }
}

function mapStateToProps(state) {
  return {
    survey: state.data.survey
  };
}
export default connect(
  mapStateToProps
)(CheckQType);
