import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../css/AECOM_logo_white.svg"
import {loadOnline} from "../actions/index";
import share from "../css/shareicon.svg"
import { withRouter } from 'react-router-dom';





export class MenuItem extends Component {

  constructor(props) {
    super(props);
        this.getShareUrl = this.getShareUrl.bind(this);
        this.LoadSurvey = this.LoadSurvey.bind(this);

  }

  componentDidMount(){

  }
  getShareUrl(el){
      this.props.ToggleMenu()
     window.location.href = `mailto:?subject=${this.props.survey[0].name} Survey to complete&body=Here is the link to complete the survey ${this.props.WebRoot}aecom?surveyID=${this.props.surveyID}%26saveid=${this.props.Survey[0]}`;
  }

  LoadSurvey(el){
    this.props.ToggleMenu()
    this.props.loadOnline(this.props.Survey[0])
    this.props.history.push({pathname:'/aecom/survey'});


  }

  GetDate(date){
    var date = new Date(date);
    var dd = date.getDate();
        var mm = date.getMonth()+1;
        var yyyy = date.getFullYear();

              if(dd<10)
              {
                  dd='0'+dd;
              }

              if(mm<10)
              {
                  mm='0'+mm;
              }

              return dd+'-'+mm+'-'+yyyy;
  }


  render() {
    return (

<li onClick={this.LoadSurvey}>{
  this.GetDate(this.props.Survey[1])}
  <img className="ShareIcon" src={share} onClick={this.getShareUrl}/>
  </li>
    );
  }
}

function mapStateToProps(state) {
  return {
    survey: state.data.survey,
    localSurveys:state.data.localSurveys,
    strapiRoot:state.data.strapiRoot,
    WebRoot:state.data.WebRoot,
    surveyID:state.data.surveyID
  };
}

export default withRouter(connect(
  mapStateToProps,
  {loadOnline}
)(MenuItem));
