import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';
import dataReducer from "../reducers/index";
import thunk from "redux-thunk";


const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  data: dataReducer,
  form: formReducer
});

const store = createStore(rootReducer,  storeEnhancers(applyMiddleware(thunk)));
export default store;
