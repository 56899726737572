import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../css/AECOM_logo_white.svg"
import {loadOnline} from "../actions/index";
import share from "../css/shareicon.svg"
import { withRouter } from 'react-router-dom';
import InnerResult from './innerResult'
import _ from 'underscore';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf';








export class FullResult extends Component {

  state = {
      fullresult:false
  };

  constructor(props) {
    super(props);
        this.getShareUrl = this.getShareUrl.bind(this);
        this.LoadSurvey = this.LoadSurvey.bind(this);
          this.makePDF = this.makePDF.bind(this);

  }

  makePDF(){
    window.html2canvas = html2canvas;


       const doc = new jsPDF('p', 'px', 'a4');
       		const call = () => {
       			console.log('saving');

       			doc.save('test.pdf');
       		};
          doc.addFont('Arial');
          doc.setFont("Arial");
       		doc.fromHTML(document.getElementById('pdf'), 30, 30, {}, call);


  }



  getShareUrl(el){
      this.props.ToggleMenu()
     window.location.href = `mailto:?subject=${this.props.survey[0].name} Survey to complete&body=Here is the link to complete the survey ${this.props.WebRoot}aecom?surveyID=${this.props.surveyID}%26saveid=${this.props.Survey[0]}`;
  }

  LoadSurvey(el){
    this.props.ToggleMenu()
    this.props.loadOnline(this.props.Survey[0])
    this.props.history.push({pathname:'/aecom/survey'});


  }


  GetDate(date){
    var date = new Date(date);
    var dd = date.getDate();
        var mm = date.getMonth()+1;
        var yyyy = date.getFullYear();

              if(dd<10)
              {
                  dd='0'+dd;
              }

              if(mm<10)
              {
                  mm='0'+mm;
              }

              return dd+'-'+mm+'-'+yyyy;
  }
  MySurvey(userID){
    if(userID === this.props.userID){
      return "X"
    }

  }


  // <img className="ShareIcon" src={share} onClick={this.getShareUrl}/>
  render() {
    //


// stupidly complicated function that could be imporved
// reorders the answers into the same order as the sections, pushes responses to a temporary section arrays
// looks for duplicate keys in the responses and pushes the values to one key
    let clonedObj = Object.assign([], this.props.survey[0].sections)
    let responses = this.props.result.responses
    for(let section of clonedObj){
      for (var key in responses) {
          if (responses.hasOwnProperty(key)) {
            if(key === section.section_name.replace(/ /g, '_').replace(/[^\w\s]/gi, '')){
                for (var mergekey in responses[key]) {
                    if(previous === mergekey.replace(/ /g, '_').toString()){
                      _.extend(responses[key][mergekey], previousObject)
                      delete responses[key][previous]
                    }
                    let previous = mergekey.replace(/ /g, '_').toString()
                    let previousObject = responses[key][mergekey]
                }

              section.responses = responses[key]
            }
            }
      }
    }
    //then removes any sections that have no responses
    let ResultswithoutNull = clonedObj.reduce((acc, thing) => {
      if (thing.responses !== undefined) {
        acc.push(thing);
      }
      return acc;
    }, [])

//remove the first 2 sections if the exist
    for(let section of clonedObj){
      if(section.section_name === "Your details"){
        ResultswithoutNull.shift()
      }
      if(section.section_name === "Interviewer details"){
        ResultswithoutNull.shift()
      }
    }
    console.log(ResultswithoutNull)







if(ResultswithoutNull !== undefined){
    return (
<div className="ink-grid" >
<div className="results_item" id="pdf" onClick={this.props.closeFull}>
<div className="column-group">

<div className="all-10 push-left"><h1>Client</h1><br/>{this.props.result.client}</div>
<div className="all-10 push-center"><h1>Client Name</h1><br/>{this.props.result.responses.Your_details.First_name + " "+ this.props.result.responses.Your_details.Surname}</div>
<div className="all-10 push-center"><h1>Completed on</h1><br/>{this.GetDate(this.props.result.updatedAt)}</div>
<div className="all-10 push-center"><h1>Completed by me</h1><br/>{this.MySurvey(this.props.result.userID)}</div>

</div>
<div className="column-group top-space">
<div className="all-10 push-left"><h1>Client Email</h1><br/>{this.props.result.responses.Your_details.Email}</div>
<div className="all-10 push-center"><h1>Client Industry</h1><br/>{this.props.result.responses.Your_details.Industry}</div>
<div className="all-10 push-center"><h1>Client Region</h1><br/>{this.props.result.responses.Your_details.Region}</div>
<div className="all-10 push-center"></div>

</div>
{ this.props.result.responses.Interviewer_details !== undefined ?
  (
    <div className="column-group top-space">
    <div className="all-10 push-left"><h1>Interviewer Email</h1><br/>{this.props.result.responses.Interviewer_details.Email}</div>
    <div className="all-10 push-center"><h1>Interviewer Name</h1>{this.props.result.responses.Interviewer_details.First_name + " "+ this.props.result.responses.Interviewer_details.Surname}</div>
    <div className="all-10 push-center"><h1>End Market</h1>{this.props.result.responses.Interviewer_details.End_Market}</div>
    <div className="all-10 push-center"><h1>AECOM Region</h1>{this.props.result.responses.Interviewer_details.AECOM_Region}</div>
    </div>
  ):""}


            <div className="column-group top-space gutters fullresult">
{ResultswithoutNull.map(function (section) {
          return (

                <div className="all-50 tiny-100 small-100 top-space result">

<div className="all-100 push-center"><h2>{section.section_name}</h2></div>
{Object.entries(section.responses).map(function ([key,value]){

                console.log(key,value)
                return (
                  <div className="all-100 push-center entry">
                  <div className="all-100 push-center entryHeader">{key.replace(/_/g, ' ')}</div>
                  <InnerResult innerResults={value}/>
                  </div>
                );
              })}

              </div>


             );
           })}
            <div className="all-100 push-left">
           <div className="column-group top-space">
           <div className="all-50 push-left">
       <button onClick={this.makePDF} className="button">save pdf</button>
       </div>
            </div>
       </div>

    </div>


</div>
</div>
)}else{
  return ( <div> Loading</div>)
}


  }
}

function mapStateToProps(state) {
  return {
    survey: state.data.survey,
    localSurveys:state.data.localSurveys,
    strapiRoot:state.data.strapiRoot,
    WebRoot:state.data.WebRoot,
    surveyID:state.data.surveyID,
        userID:state.data.userID,
  };
}



export default connect(
  mapStateToProps,
  {loadOnline}
)(FullResult);
