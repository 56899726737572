import React, { Component } from "react";
import { connect } from "react-redux";
import { getSurvey, setSurveyType, addResponse, updateResponse, loadOffline, setLocalSurveys,getLocalSurvey, loadOnline,setStages,loadSurvey, setUserID, getCompleted } from "../actions/index";
import Slider from "react-slick";
import Questions from "./questions";
import Menu from "./menu";
import Header from "./header"
import ChooseSurvey from "./choosesurvey"
import _ from 'underscore';
import { Link } from 'react-router-dom';


import "../css/slick.min.css";
import "../css/slick-theme.min.css";
import "../css/slide_styles.css";
import ReactMarkdown from "react-markdown"
import { Field, reduxForm, reset } from 'redux-form';
import QueryString from 'query-string'
import Result from './result'
import * as JsSearch from 'js-search';
import SearchBar from './searchBar';
import CryptoJS from 'crypto-js';
const KEY = 'A1!AsWJ%xbl"0>?';

let SearchWait = "";

export class ViewAll extends Component {
  state = {
    activeSlide: 0,
    activeSlide2: 0,
    MenuOpen:false,
    search: '',
    Searchcontent: false,
    content: false,
    Results:[]

  };

  //
  // this.state = {
  //   menuOpen: false,
  //   search: '',
  //   content: false,
  //   PlayResults: [],
  //   selectedFilters: "",
  //   SelectedFilterArray: [],
  //   SelectedDocuments: [],
  //   activeWindow: "",
  //   Searchcontent: false,
  //
  // }

  constructor(props) {
    super(props);
    this.ToggleMenu = this.ToggleMenu.bind(this);
        this.sendSurvey = this.sendSurvey.bind(this);
        this.getStarted = this.getStarted.bind(this);
        this.Search = new JsSearch.Search("_id");
        this.Search.addIndex(this.props.completedResults.client);
        this.Search.addIndex(this.props.completedResults.endMarket);
        this.Search.addIndex(this.props.completedResults.industry);
        this.ClientFilter = []
        this.EndMarketFilter = []
        this.IndustryFilter = []
        this.RegionFilter =[]
  }



  componentDidMount() {


      this.props.setSurveyType("internal");


      if(sessionStorage.getItem('userInfo') !== null){
                this.props.setUserID(JSON.parse(sessionStorage.getItem('userInfo'))._id)
      }

    this.props.getCompleted(this.props.surveyID,true)


  }
componentDidUpdate(prevProps, prevState) {
    if(this.props.survey === undefined){
    this.props.getSurvey();
  }

  if(this.props.surveyID === ""){
    this.props.getLocalSurvey();

  }
  if(this.props.completedResults.length <= 0 && this.props.surveyID !== ""){
      this.props.getCompleted(this.props.surveyID, true)

  }

  if(this.props.completedResults.length > 0){
    if(prevProps.completedResults !== this.props.completedResults){
      console.log("SET SEARCH")
      this.Search = new JsSearch.Search("_id");
      this.Search.addIndex("client");
      this.Search.addIndex("endMarket");
      this.Search.addIndex("industry");
      this.Search.addIndex("responses");
      this.Search.addIndex("region");
      this.Search.addIndex(['responses', 'Your_details','Industry']);
      this.Search.addIndex(['responses', 'Your_details','Email']);
      this.Search.addIndex(['responses', 'Your_details','First_name']);
      this.Search.addIndex(['responses', 'Your_details','Surname']);
      this.Search.addIndex(['responses', 'Your_details','Region']);
      this.Search.addIndex(['responses', 'Interviewer_details','Email']);
      this.Search.addIndex(['responses', 'Interviewer_details','First_name']);
      this.Search.addIndex(['responses', 'Interviewer_details','Surname']);
      this.Search.addIndex(['responses', 'Interviewer_details','Interview_location']);


      this.Search.addDocuments(this.props.completedResults);
      console.log(this.Search)
      this.setState({"Results": _.sortBy(this.props.completedResults, ['_id'], ['asc']).reverse()})

      let ClientFilter = []
      let EndMarketFilter = []
      let IndustryFilter = []
      let RegionFilter =[]

      for(let clients of this.props.completedResults){
        if((clients.client !== undefined) && (clients.client !== ""))
        this.ClientFilter.push({value:clients.client,label:clients.client})
        if((clients.endMarket !== undefined ) && (clients.endMarket !== ""))
        this.EndMarketFilter.push({value:clients.endMarket,label:clients.endMarket})
        if((clients.industry !== undefined) && (clients.industry !== ""))
        this.IndustryFilter.push({value:clients.industry,label:clients.industry})
        if((clients.region !== undefined) && (clients.region !== ""))
        this.RegionFilter.push({value:clients.region,label:clients.region})
      }
      _.uniq(ClientFilter)
      _.uniq(EndMarketFilter)
      _.uniq(IndustryFilter)
      _.uniq(RegionFilter)

      console.log(ClientFilter,EndMarketFilter,IndustryFilter,RegionFilter)
}
}




  }


  ToggleMenu(){
    if(this.state.MenuOpen){
          this.setState({ MenuOpen: false })
    }else{
          this.setState({ MenuOpen: true })
    }



  }

    sendSurvey(){
            window.location.href = `mailto:?subject=AECOM ${this.props.survey[0].name} survey&body=A key activity in our client listening programme is to gather actionable insights on our bids and improve the services we offer and deliver to our clients / you. %0D%0A%0D%0A Thankyou for agreeing to take this survey. It should take no more than 10 – 15 mins to complete. %0D%0A%0D%0A You can start the survey here and complete it at your lesuire. Results will be saved until you submit the survey. %0D%0A ${this.props.WebRoot}?surveyID=${this.props.surveyID}%26userID=${this.props.userID}`;

    }

    getStarted(){
              this.props.history.push({pathname:'/aecom/survey'});

    }




  GetSectionTitle(el){
    if(this.props.surveyType === "external"){
      return{__html:el.section_name}
    }else{
      if( el.External_section_name === undefined){
        return{__html: el.section_name}
      }else{
        return{__html: el.External_section_name}
      }
    }
  }


  handleChange = (event) => {
    console.log("Change", event)
    let selff = this //************ add this
        let Val=""
  if (event !== null){
    if (Array.isArray(event)){
        Val = event[0].value

        if (event.length > 0) {

          clearTimeout(SearchWait);
          SearchWait = setTimeout(function() {
            selff.setState({
              Results: _.sortBy(selff.Search.search(Val), ['_id'], ['asc']).reverse()
            });
          }, 100);
          this.setState({content: true});
          this.setState({Searchcontent: true});
        } else {
          console.log("no results")
          this.setState({content: false});
          this.setState({Searchcontent: false});
          this.setState({"Results":_.sortBy(this.props.completedResults, ['_id'], ['asc']).reverse()})
          // selff.setState({"PlayResults": DataStore.getAll()})
        }

    }else{
        Val = event.target.value

    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const name = target.name;
    this.setState({[name]: value});
    console.log(selff.Search.search(Val))
    if (event.target.value.length > 0) {

      clearTimeout(SearchWait);
      SearchWait = setTimeout(function() {
        selff.setState({
          Results: _.sortBy(selff.Search.search(Val), ['_id'], ['asc']).reverse()
        });
      }, 100);
      this.setState({content: true});
      this.setState({Searchcontent: true});
    } else {
      console.log("no results")
      this.setState({content: false});
      this.setState({Searchcontent: false});
      this.setState({"Results":_.sortBy(this.props.completedResults, ['_id'], ['asc']).reverse()})
      // selff.setState({"PlayResults": DataStore.getAll()})
    }
  }
} else {
  console.log("no results")
  this.setState({content: false});
  this.setState({Searchcontent: false});
  this.setState({"Results":_.sortBy(this.props.completedResults, ['_id'], ['asc']).reverse()})
  // selff.setState({"PlayResults": DataStore.getAll()})
}

  }




  render() {



    if(this.props.ready){
      let cardStyle = {
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),url(${this.props.strapiRoot + this.props.survey[0].image.url})`
      };
    return (


      <div>
      <Header ToggleMenu={this.ToggleMenu} />
      <div className={this.props.surveyType === "internal" ? "":"hide"}>
      <Menu ToggleMenu={this.ToggleMenu} MenuOpen={this.state.MenuOpen}  history={this.props.history} sendSurvey={this.sendSurvey}/>
      </div>
      <div className="ink-grid">
        <div className="column-group">
          <div className="all-100 top-space">
          <div className="column-group">
            <div className="all-100 top-space">
              <h1>{this.props.survey[0].name}</h1>
              </div>
              <div className="all-100 top-space">
              <SearchBar search={this.state.search} Searchcontent={this.state.Searchcontent} handleChange={this.handleChange} filters={[this.ClientFilter,this.EndMarketFilter,this.IndustryFilter,this.RegionFilter]} filterName={["Client", "End Market", "Industry" ,"Region"]}/>
              <div className="column-group results_header">

              <div className="all-10 push-left">Client</div>
              <div className="all-10 push-center">Name</div>
              <div className="all-10 push-center">Survey completed</div>
              <div className="all-10 push-center">My Survey</div>
              </div>
                {this.state.Results.map((el, index) => (
                <Result result={el}/>
                ))}
                </div>
            </div>
          </div>
        </div>
      </div>

<footer>


</footer>

</div>
    );
  }else{
    if(this.props.surveyID === ""){
      return (
        <div>
        <Header ToggleMenu={this.ToggleMenu} />
        <ChooseSurvey/>
</div>
      )
    }else{
    return (
<div>LOADING</div>
    )
  }

  }
  }
}
function mapStateToProps(state) {
  return {
    survey: state.data.survey,
    sections: state.data.sections,
    PerformQuestions:state.data.PerformQuestions,
    ready:state.data.ready,
    strapiRoot:state.data.strapiRoot,
    surveyType:state.data.surveyType,
    answers: state.form.bid,
    responseID:state.data.ResponseID,
    initialValues:state.data.initalValues,
    BidStages:state.data.BidStages,
    submitFailed: state.form.submitFailed,
    surveyID:state.data.surveyID,
    userID:state.data.userID,
    WebRoot:state.data.WebRoot,
    completedResults:state.data.completedResults



  };
}



export default connect(
  mapStateToProps,
  { getSurvey, setSurveyType, addResponse, updateResponse,setLocalSurveys,getLocalSurvey, loadOffline, loadOnline,setStages,loadSurvey,setUserID,getCompleted  }
)(ViewAll)

//
// Intro = connect(
//   mapStateToProps,
//   { getSurvey, setSurveyType, addResponse, updateResponse,setLocalSurveys,getLocalSurvey, loadOffline  }
// )(Intro)
//
//
// export default reduxForm({
//     form: 'bid',
//   initialValues:
// })(Intro);
