// src/js/actions/index.js
import { DATA_LOADED,DATA_ERROR,DATA_LOADING,SECTIONS_READY,GENERAL_READY,PERFORMANCE_READY,SET_SURVEY_TYPE,RESPONSE_UPDATING,RESPONSE_UPDATED,RESPONSE_ERROR,RESPONSE_CREATED,FORM_LOADED,LOCAL_SURVEYS,LOAD_LOCAL_SURVEYS, LOADED_SURVEY, FORM_LOADING, FORM_ERROR,SET_STAGES,STAGE_READY,STAGE_ERROR,SET_SURVEYID,SET_USER, ALL_SURVEYS, RESULTS_LOADED } from "../constants/action-types";
import axios from 'axios';
import store from '../stores';
import _ from 'underscore';
import CryptoJS from 'crypto-js';

const appUrl = store.getState().data.strapiRoot
const surveyID = store.getState().data.surveyID
//const appUrl = 'http://publications.aecom.com/accelerate'
const surveyEndPoint = `${appUrl}/surveys?_id=${surveyID}`;
const generalEndPoint = `${appUrl}/generals`;
const stagesEndPoint = `${appUrl}/stages`;
const performancesEndPoint = `${appUrl}/performances`;
const KEY = 'A1!AsWJ%xbl"0>?';


let axiosConfig = {
  headers: {
    "Accept": "application/json; odata=verbose",
    "Response-Type": "application/json; odata=verbose",
    "Content-Type": "application/json; odata=verbose",
    'Access-Control-Allow-Origin': '*',
  }
}


export function loadSurvey(id,results){


  console.log("LOAD")
return function(dispatch) {

dispatch({ type: "SET_SURVEYID", payload: id });
if(results){
    dispatch(getCompleted(id));
}else{
  dispatch(getSurvey(id));
}

}
}

export function getSurvey(id) {
  return function(dispatch) {
    dispatch({ type: "DATA_LOADING" });
    return axios.get(`${appUrl}/surveys?_id=${id}`)
        .then((response) => {
    let Sections = []

      if(store.getState().data.surveyType === "internal"){
            dispatch({ type: "SECTIONS_READY", payload: response.data[0].sections });

      }else{
            Sections = []
            for (let section of response.data[0].sections){
                if(!section.Internal_section){
                  Sections.push(section)
                }




            }
          dispatch({ type: "SECTIONS_READY", payload: Sections });
      }
      dispatch({ type: "DATA_LOADED", payload: response.data });





          dispatch(getQuestions());
      }).catch((error) => {
        dispatch({ type: "DATA_ERROR", payload: error });

      });
  };
}


export function getAllSurveys(id) {
  return function(dispatch) {
    dispatch({ type: "DATA_LOADING" });
    return axios.get(`${appUrl}/surveys`)
        .then((response) => {
        dispatch({ type: "ALL_SURVEYS", payload: response.data });

        }).catch((error) => {
          dispatch({ type: "DATA_ERROR", payload: error });

        });
      }
    }


function getQuestions() {
  return function(dispatch) {
    dispatch({ type: "DATA_LOADING" });
    return axios.get(generalEndPoint)
        .then((response) => {
          dispatch({ type: "GENERAL_READY", payload: response.data });
          dispatch(getPerformance());

      }).catch((error) => {

        dispatch({ type: "DATA_ERROR", payload: error });

      });
  };
}
function getPerformance() {
  return function(dispatch) {
    dispatch({ type: "DATA_LOADING" });
    return axios.get(performancesEndPoint)
        .then((response) => {
          dispatch({ type: "PERFORMANCE_READY", payload: response.data });
          dispatch(getStages());
      }).catch((error) => {

        dispatch({ type: "DATA_ERROR", payload: error });

      });
  };
}

function getStages() {
  return function(dispatch) {
    dispatch({ type: "DATA_LOADING" });
    return axios.get(stagesEndPoint)
        .then((response) => {
          console.log("stage", response.data)
          dispatch({ type: "STAGE_READY", payload: response.data });
          dispatch(generateSurvey());
      }).catch((error) => {
        console.log("stage", error)
        dispatch({ type: "STAGE_ERROR", payload: error });

      });
  };
}


export function setSurveyType(type) {
      return function(dispatch) {
            dispatch({ type: "SET_SURVEY_TYPE", payload: type });

      }
}

function generateSurvey() {
  return function(dispatch) {
    for (let section of store.getState().data.sections){
      //loop though each section
      section.questions = []
      section.performance = []
      section.stage = []

      // set new question arrays for each section

      for (let question of store.getState().data.GenQuestions){
        for (let questionSection of question.sections){
        if(questionSection._id === section._id){
            section.questions.push(question)
        }
      }
      }

      for (let stage of store.getState().data.Stages){
        if(section.stages.length > 0){
          for (let stageSection of section.stages){
            if(stageSection === stage._id){
              section.stage.push(stage.stage_name)
              }
        }
      }
    }

      for (let question of store.getState().data.PerformQuestions){
        if(question.section !== null){
        if(question.section._id === section._id)
        section.performance.push(question)
      }
      }
    }


        dispatch({ type: "SURVEY_READY"});
  };
}


export function addResponse(answers, complete){


  return function(dispatch) {

    dispatch({ type: "RESPONSE_UPDATING" });
          return axios.post(`${appUrl}/responses`, {
            complete:false,
            Survey:store.getState().data.survey[0].name,
            surveyID:store.getState().data.survey[0]._id,
            userID:store.getState().data.userID
          }).then((response) => {
                dispatch({ type: "RESPONSE_CREATED", payload: response.data.id });
                dispatch(updateResponse(response.data.id,answers,complete));
            }).catch((error) => {
              dispatch({ type: "RESPONSE_ERROR", payload: error });

      });

  };

}


// export function updateResponse(id,answers,complete){
//
// console.log(answers)
//
//   return function(dispatch) {
//     let UserID =
//     dispatch({ type: "RESPONSE_UPDATING" });
// console.log(id)
// if(answers.Your_details !== undefined && id === undefined ){
//     return axios.post(`${appUrl}/auth/local/register`,{
//       username:new Date(),
//       email:answers.Your_details.Email,
//       surname:answers.Your_details.Surname,
//       firstName:answers.Your_details.First_name,
//       password:"password"
//
//     }).then((response) => {
// console.log(response)
//               dispatch(updateResponseData(response.data.user._id,id,answers,complete));
//      //dispatch({ type: "RESPONSE_UPDATED", payload: response.data });
//
//
//    }).catch((error) => {
//         console.log(error.response.data.message)
//
//           //dispatch({ type: "RESPONSE_ERROR", payload: error });
//
//     });
//   }else{
//       dispatch(updateResponseData("",id,answers,complete));
//   }
//
//   };
//
// }






export function updateResponse(id,answers,complete){


  if(complete === true){
      let Surveys = JSON.parse(localStorage.getItem('AECOMSurvey'+store.getState().data.surveyID))
      let TempSurvey=[]
      for(let survey of Surveys){
        if(survey[0] !== id){
          TempSurvey.push(survey)
        }
      }
      localStorage.setItem('AECOMSurvey'+store.getState().data.surveyID,JSON.stringify(TempSurvey));
  }

console.log(answers)
    let Answers = JSON.parse(JSON.stringify(answers))

    console.log(Answers)

    return function (dispatch){
      let Data ={
        responses:Answers,
        complete:complete
      }
  let EndMarket = ""
  let AECOMRegion = ""
  let Client =""
  let Industry =""

if(Answers.Interviewer_details !== undefined){

  Answers.Interviewer_details.Email = CryptoJS.AES.encrypt(Answers.Interviewer_details.Email, KEY).toString()
  Answers.Interviewer_details.Surname = CryptoJS.AES.encrypt(Answers.Interviewer_details.Surname, KEY).toString()
  Answers.Interviewer_details.First_name = CryptoJS.AES.encrypt(Answers.Interviewer_details.First_name, KEY).toString()
  EndMarket = Answers.Interviewer_details.End_Market
  AECOMRegion = Answers.Interviewer_details.AECOM_Region
console.log(EndMarket)




}
      if(Answers.Your_details !== undefined){
      Client = Answers.Your_details.Company
      Industry = Answers.Your_details.Industry

      Answers.Your_details.Email = CryptoJS.AES.encrypt(Answers.Your_details.Email, KEY).toString()
      Answers.Your_details.Surname = CryptoJS.AES.encrypt(Answers.Your_details.Surname, KEY).toString()
      Answers.Your_details.First_name = CryptoJS.AES.encrypt(Answers.Your_details.First_name, KEY).toString()

      }

      Data ={
        endMarket:EndMarket,
        region:AECOMRegion,
        client:Client,
        industry:Industry,
        responses:Answers,
        complete:complete
      }

      return axios.put(`${appUrl}/responses/${id}`,Data)
      .then((response) => {

       dispatch({ type: "RESPONSE_UPDATED", payload: response.data });


        }).catch((error) => {

            dispatch({ type: "RESPONSE_ERROR", payload: error });

  });




    }

}







export function loadOffline(){
    return function(dispatch) {
      let offlineSurvey = new Object()

  if(JSON.parse(localStorage.getItem(store.getState().data.surveyID)) !== null){
    offlineSurvey = JSON.parse(localStorage.getItem(store.getState().data.surveyID))
  }

      dispatch({ type: "FORM_LOADED", payload: offlineSurvey });
}

}

export function loadOnline(id){

  console.log("LOADONLINE")
    return function(dispatch) {
  dispatch({ type: "FORM_LOADING" });
  return axios.get(`${appUrl}/responses/${id}`)
      .then((response) => {

   _.filter(response.data.responses.Your_details, function(value,key ){
        let bytes  = CryptoJS.AES.decrypt(value, KEY);
        let plaintext = bytes.toString(CryptoJS.enc.Utf8);
        if(key === "Email" ){
          response.data.responses.Your_details.Email = plaintext
        }
        if(key === "First_name" ){
          response.data.responses.Your_details.First_name = plaintext
        }
        if(key === "Surname" ){
          response.data.responses.Your_details.Surname = plaintext
        }
  })
  _.filter(response.data.responses.Interviewer_details, function(value,key ){
       let bytes  = CryptoJS.AES.decrypt(value, KEY);
       let plaintext = bytes.toString(CryptoJS.enc.Utf8);
       if(key === "Email" ){
         response.data.responses.Interviewer_details.Email = plaintext
       }
 })


        dispatch({ type: "FORM_LOADED", payload: response.data.responses });
        dispatch({ type: "RESPONSE_CREATED", payload: response.data.id });
    }).catch((error) => {
        dispatch({ type: "FORM_ERROR", payload: error });
    });

}

}


export function setLocalSurveys(localSurveys){
    return function(dispatch) {
        localStorage.setItem('AECOMSurvey'+store.getState().data.surveyID,JSON.stringify(localSurveys));
        dispatch({ type: "LOCAL_SURVEYS", payload: localSurveys });
    }
}

export function getLocalSurvey(){
    return function(dispatch) {
        let Surveys = JSON.parse(localStorage.getItem('AECOMSurvey'+store.getState().data.surveyID))
        dispatch({ type: "LOCAL_SURVEYS", payload: Surveys });
    }
}


export function setStages(stages){
    return function(dispatch) {

        dispatch({ type: "SET_STAGES", payload: stages });
    }
}

export function setUserID(userID){
    return function(dispatch) {
        dispatch({ type: "SET_USER", payload: userID});
    }
}

export function getCompleted(id,myresults){
console.log(id)
let URL=""
if(myresults){
 URL = `${appUrl}/responses?complete=true&surveyID=${id}&userID=${store.getState().data.userID}`
}else{
   URL = `${appUrl}/responses?complete=true&surveyID=${id}`
}
    return function(dispatch) {
  dispatch({ type: "DATA_LOADING" });
  return axios.get(URL)
      .then((response) => {

        for(let section of response.data){
          section.responses.Your_details.Email  = CryptoJS.AES.decrypt(section.responses.Your_details.Email, KEY).toString(CryptoJS.enc.Utf8);
            section.responses.Your_details.First_name  = CryptoJS.AES.decrypt(section.responses.Your_details.First_name, KEY).toString(CryptoJS.enc.Utf8);
            section.responses.Your_details.Surname  = CryptoJS.AES.decrypt(section.responses.Your_details.Surname, KEY).toString(CryptoJS.enc.Utf8);
                if ('Interviewer_details' in section.responses){
                  section.responses.Interviewer_details.Email  = CryptoJS.AES.decrypt(section.responses.Interviewer_details.Email, KEY).toString(CryptoJS.enc.Utf8);
                  section.responses.Interviewer_details.First_name  = CryptoJS.AES.decrypt(section.responses.Interviewer_details.First_name, KEY).toString(CryptoJS.enc.Utf8);
                  section.responses.Interviewer_details.Surname  = CryptoJS.AES.decrypt(section.responses.Interviewer_details.Surname, KEY).toString(CryptoJS.enc.Utf8);

                }
        }


        console.log(response.data)




        dispatch({ type: "RESULTS_LOADED", payload: response.data});


      }).catch((error) =>{
                dispatch({ type: "DATA_ERROR", payload: error });
      })
    }
  }
