import React, {Component} from 'react';
import * as JsSearch from 'js-search';
import '../css/input_advanced.css'
import Select from 'react-select';

class SearchBar extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      // search: '',
      // content:false,
      // Searchcontent:false,
    };
  }


  render() {
console.log(this.props.filters)
    // console.log(this.state.search)

    return (
      <div className="search column-group gutters">
      <div className="all-30">
        <form onKeyPress={event => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }}>
          <input className="searchBar" type="text" name="search" id="search" autoComplete='off' value={this.props.search} onChange={this.props.handleChange}/>
          <span id="searchbar" className="bar"></span>
          <label className={ this.props.Searchcontent ? "inputup":"" } htmlFor="search">Search</label>
        </form>
        </div>
        {
          this.props.filters.map((item, index) => (
            <div className="all-15 push-right">
            <Select style={{
                zIndex: 100000,
                background:"black",
                border:"solid 1px black"
              }} key={index} menuContainerStyle={{
                zIndex: 100000
              }} placeholder={this.props.filterName[index]} onChange={this.props.handleChange} options={item} isSearchable isMulti classNamePrefix="react-select"/>
</div>

          ))
  }
      </div>

  )

  }

}
export default SearchBar;
