/**
 *
 * AuthPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { findIndex, get, map, replace, set } from 'lodash';
import { Link } from 'react-router-dom';

import Button from '../../components/Button';
import Input from '../../components/InputsIndex';
import Logo from "../../css/AECOM_logo_black.svg"
import './styles.css';


// Utils
import auth from '../../utils/auth';
import request from '../../utils/request';

import form from './forms.json';

class AuthPage extends React.Component {
  state = { value: {}, errors: [], didCheckErrors: false };

  componentDidMount() {
    console.log(this.props.history)
    this.generateForm(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.authType !== this.props.match.params.authType) {
      this.generateForm(nextProps);
    }
  }

  getRequestURL = () => {
    let requestURL;

    switch (this.props.match.params.authType) {
      case 'login':
        requestURL = 'https://survey.aecom-digital.com/survey/auth/local';
        break;
      case 'register':
        requestURL = 'https://survey.aecom-digital.com/survey/auth/local/register';
        break;
      case 'reset-password':
        requestURL = 'https://survey.aecom-digital.com/survey/auth/reset-password';
        break;
      case 'forgot-password':
        requestURL = 'https://survey.aecom-digital.com/survey/auth/forgot-password';
        break;
      default:
    }

    return requestURL;
  };

  generateForm = props => {
    const params = props.location.search
      ? replace(props.location.search, '?code=', '')
      : props.match.params.id;
    this.setForm(props.match.params.authType, params);
  };

  handleChange = ({ target }) =>
    this.setState({
      value: { ...this.state.value, [target.name]: target.value },
    });

  handleSubmit = e => {
    e.preventDefault();
    const body = this.state.value;
    const requestURL = this.getRequestURL();

    // This line is required for the callback url to redirect your user to app
    if (this.props.match.params.authType === 'forgot-password') {
      set(body, 'url', 'http://survey.aecom-digital.com/auth/reset-password');
    }

    request(requestURL, { method: 'POST', body: this.state.value })
      .then(response => {
        console.log(response)

        if(response.ok){
          const errors = [
            { name: 'message', errors: ["please check your email for a password reset link"] },
          ];
          this.setState({ didCheckErrors: !this.state.didCheckErrors, errors });
           this.props.history.push({pathname:'/auth/message'});
        }


      if(response.user.confirmed){
        auth.setToken(response.jwt, body.rememberMe);
        console.log(response.user)
        auth.setUserInfo(response.user, body.rememberMe);
        this.redirectUser();
      }else{
          this.props.history.push({pathname:'/auth/message'});
      }
      })
      .catch(err => {
        // TODO handle errors for other views
        // This is just an example
        console.log(err)
        if(err !== undefined){
        const errors = [
          { name: 'identifier', errors: [err.response.payload.message] },
        ];
        this.setState({ didCheckErrors: !this.state.didCheckErrors, errors });
      }
      });
  };

  redirectUser = () => {
    console.log(this.props.history.location.state)
if(this.props.history.location.state === undefined){
  this.props.history.push({pathname:'/aecom'});
}else{
    this.props.history.push({pathname:'/aecom',search:this.props.history.location.state.from.search});
}

  };

  /**
   * Function that allows to set the value to be modified
   * @param {String} formType the auth view type ex: login
   * @param {String} email    Optionnal
   */
  setForm = (formType, email) => {
    const value = get(form, ['data', formType], {});

    console.log(value)

    if (formType === 'reset-password') {
      set(value, 'code', email);
    }

    this.setState({ value });

  };

  /**
   * Check the URL's params to render the appropriate links
   * @return {Element} Returns navigation links
   */
  renderLink = () => {
    if (this.props.match.params.authType === 'login') {
      return (
        <div>
          <Link to="/auth/forgot-password">Forgot Password</Link>
          &nbsp;or&nbsp;
          <Link to="/auth/register">register</Link>
        </div>
      );
    }

    return (
      <div>
        <Link to="/auth/login">Ready to signin</Link>
      </div>
    );
  };

  render() {
    const divStyle =
      this.props.match.params.authType === 'register'
        ? { marginTop: '3.2rem' }
        : { marginTop: '.9rem' };
    const inputs = get(form, ['views', this.props.match.params.authType], []);

    return (
      <div className="authPage">


      {this.props.match.params.authType === 'message' ? (
        <div>
        <span>Please check your email</span>
      </div>
      ) : (




        <div className="wrapper">
          <div className="headerContainer">
            {this.props.match.params.authType === 'register' ? (
              <span>Welcome !</span>

            ) : (

              <img src={Logo} alt="logo" />

            )}
          </div>
          <div className="headerDescription">
            {this.props.match.params.authType === 'register' ? (
              <div>
              <span>Please register to access the app.</span>
</div>
            ) : (
              <div>
              <h1>Client Listening</h1>
            <p>A key activity in our client listening programme is to gather actionable insights on our bids to improve the services we deliver to our clients and ultimately, win more work. <br/><br/>We have developed an online survey platform that enables us to do so quickly and efficiently. <br/><br/>
                            Please login or register for an account below</p>
                            </div>
            )}

          </div>
          <div className="formContainer" style={divStyle}>
            <div className="container-fluid">
              <form onSubmit={this.handleSubmit}>
                <div className="row" style={{ textAlign: 'start' }}>
                  {map(inputs, (input, key) => (
                    <Input
                      autoFocus={key === 0}
                      customBootstrapClass={get(input, 'customBootstrapClass')}
                      didCheckErrors={this.state.didCheckErrors}
                      errors={get(
                        this.state.errors,
                        [
                          findIndex(this.state.errors, ['name', input.name]),
                          'errors',
                        ],
                        []
                      )}
                      key={get(input, 'name')}
                      label={get(input, 'label')}
                      name={get(input, 'name')}
                      onChange={this.handleChange}
                      placeholder={get(input, 'placeholder')}
                      type={get(input, 'type')}
                      validations={{ required: true }}
                      value={get(this.state.value, get(input, 'name'), '')}
                    />
                  ))}
                  <div className="col-md-12 buttonContainer">
                    <Button
                      label="Submit"
                      style={{ width: '100%' }}
                      primary
                      type="submit"
                    />
                  </div>

                </div>
              </form>
            </div>
          </div>
          <div className="linkContainer">{this.renderLink()}</div>

        </div>
            )}
      </div>
    );
  }
}

AuthPage.defaultProps = {};
AuthPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default AuthPage;
