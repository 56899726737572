import {  SURVEY_READY,DATA_LOADED,DATA_ERROR,DATA_LOADING,SECTIONS_READY,GENERAL_READY,PERFORMANCE_READY,SET_SURVEY_TYPE,RESPONSE_UPDATING,RESPONSE_UPDATED,RESPONSE_ERROR,RESPONSE_CREATED,FORM_LOADED, LOCAL_SURVEYS,LOAD_LOCAL_SURVEYS, LOADED_SURVEY,FORM_LOADING, FORM_ERROR,SET_STAGES,STAGE_READY,STAGE_ERROR,SET_SURVEYID,SET_USER,ALL_SURVEYS,RESULTS_LOADED} from "../constants/action-types";

const initialState = {
  survey: [],
  sections:[],
  GenQuestions:[],
  PerformQuestions:[],
  error:"",
  ready:false,
  strapiRoot:"https://apps.aecom-digital.com/survey",
  WebRoot:"https://survey.aecom-digital.com/",
  surveyType:"",
  response:[],
  ResponseID:"",
  initalValues:new Object(),
  localSurveys:[],
  BidStages:[],
  Stages:[],
  surveyID:"",
  userID:"",
  AllSurveys:[],
  completedResults:[]
};




function dataReducer(state = initialState, action) {

    if (action.type === DATA_ERROR) {
       console.log(action.payload)
      return Object.assign({}, state, {
        error: "there was a problem loading the data - " + action.payload
      });
     }
    if (action.type === DATA_LOADED) {
      return Object.assign({}, state, {
        survey: state.survey.concat(action.payload),
        error: ""
      });
    }
    if (action.type === SECTIONS_READY) {
      return Object.assign({}, state, {
        sections: action.payload,
        error: ""
      });
    }
    if (action.type === GENERAL_READY) {
    return Object.assign({}, state, {
    GenQuestions: state.GenQuestions.concat(action.payload),
    error: ""
    });
    }
    if (action.type === PERFORMANCE_READY) {
    return Object.assign({}, state, {
    PerformQuestions: state.PerformQuestions.concat(action.payload),
    error: ""
    });
    }

  if (action.type === DATA_LOADING) {
return Object.assign({}, state, {
  error: "LOADING"
});
}
if (action.type === SURVEY_READY) {
return Object.assign({}, state, {
ready: true
});
}

if (action.type === SET_SURVEY_TYPE) {
return Object.assign({}, state, {
surveyType: action.payload
});
}

if (action.type === RESPONSE_CREATED) {
return Object.assign({}, state, {
    ResponseID: action.payload,
    error: ""
});
}



if (action.type === RESPONSE_UPDATING) {
return Object.assign({}, state, {
  error: "UPDATING"
});
}
if (action.type === RESPONSE_ERROR) {
return Object.assign({}, state, {
  error: action.payload
});
}


if (action.type === RESPONSE_UPDATED) {
return Object.assign({}, state, {
response: action.payload
});
}

if (action.type === FORM_LOADED) {
return Object.assign({}, state, {
initalValues: action.payload
});
}

if (action.type === LOCAL_SURVEYS) {
return Object.assign({}, state, {
localSurveys: action.payload
});
}

if (action.type === SET_STAGES) {
return Object.assign({}, state, {
BidStages: action.payload
});
}
if (action.type === STAGE_READY) {
return Object.assign({}, state, {
Stages: action.payload
});
}


if (action.type === STAGE_ERROR) {
return Object.assign({}, state, {
error: action.payload
});
}


if (action.type === SET_SURVEYID) {
return Object.assign({}, state, {
surveyID: action.payload
});
}

if (action.type === SET_USER) {
return Object.assign({}, state, {
userID: action.payload
});
}

if (action.type === ALL_SURVEYS) {
return Object.assign({}, state, {
AllSurveys: action.payload
});
}

if (action.type === RESULTS_LOADED) {
return Object.assign({}, state, {
completedResults: action.payload
});
}













  return state;
};








export default dataReducer;
