import React, { Component } from 'react';
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import createHistory from 'history/createBrowserHistory'
import logo from './logo.svg';

import './css/fonts.css';
import './css/ink-flex.css';
import './App.css';
import store from "./stores/index";


import Intro from "./components/intro"
import InternalLanding from "./components/internalLanding"
import ViewAll from "./components/viewall"
import ViewMy from "./components/viewmy"


import IntroExternal from "./components/intro_external"
import Error from "./components/error"
import AuthPage from './containers/AuthPage';
import PrivateRoute from './containers/PrivateRoute';
import QueryString from 'query-string'
import {loadOnline, loadSurvey} from "./actions/index";


window.store = store;

ReactGA.initialize('UA-84589785-16');
ReactGA.pageview(window.location.pathname + window.location.search);

const history = createHistory()
  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});




class App extends Component {

componentDidMount(){
  const UserID = QueryString.parse(window.location.search);


}




  render() {




    return (
  	   <Router history={history}>
        <div>
        <Error/>
          <Switch>
            {/* A user can't go to the HomePage if is not authenticated */}
            <Route path="/auth/:authType/:id?"  component={AuthPage} />
            <Route path="/" component={IntroExternal} exact />
            <PrivateRoute exact path="/aecom" component={InternalLanding} />
            <PrivateRoute exact path="/aecom/survey" component={Intro} />
            <PrivateRoute exact path="/aecom/survey/view_all" component={ViewAll} />
              <PrivateRoute exact path="/aecom/survey/view_my" component={ViewMy} />
          </Switch>
        </div>
      </Router>



    );
  }
}

function mapStateToProps(state) {
  return {
    survey: state.data.survey
  };
}



export default connect(
  mapStateToProps,
  { loadOnline, loadSurvey}
)(App);
