import React, { Component } from "react";
import { connect } from "react-redux";
// import { updateQuestion } from "../actions/index";
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Field } from 'redux-form'

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, sectionindex, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};
const wrapperStyle = { width: 400, margin: 50 };


let stepper = 1;


export class Slider_Controller extends Component {
  // state = {
  //   showQuestions: false
  // };



  constructor(props) {
         super(props)
         this.state = { value: this.props.input.value,
         showQuestions: false }
     }

  componentDidUpdate(prevProps) {
    if(prevProps.input.value !== this.props.input.value) {
        this.setState({value: this.props.input.value})


    }


}

componentDidMount(){
  console.log(this.props.sectionindex, this.props.index)
  if(this.props.question.high_tolerance === 0 && this.props.question.low_tolerance === 0){
    this.setState({
      showQuestions: true
    })

  }
  if(this.props.input.value.length !== 0){
  let tolerance = false
  if(this.props.input.value > this.props.question.high_tolerance || this.props.input.value < this.props.question.low_tolerance){
  tolerance = true
  }
  if(this.props.input.value === 0){
    tolerance = false
  }

  if(tolerance){
     this.setState({
       showQuestions: true
     })
       setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 0);


  }
}
}




onAfterChange = (value,label) => {
let Value= (value/100)*this.props.question.steps
  let tolerance = false
  if(Value > this.props.question.high_tolerance || Value < this.props.question.low_tolerance){
  tolerance = true
  }
  if(Value === 0){
    tolerance = false
  }

if(tolerance){
try {
     this.intervalId  =  setTimeout(async () => {
             this.setState({
               showQuestions: true
             })
          }, 500);
         } catch(e) {
           console.log(e);
         }
}else{
  this.setState({
    showQuestions: false
  })

   clearInterval(this.intervalId);
}
setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 800);
this.props.input.onChange(Value)


  };

  GetPerformanceQuestionTitle(el,sectionindex, index){
    if(this.props.surveyType === "external"){
      return{__html: (sectionindex + 1) +"." + (index + 1) +". " + el.question}
    }else{
      if( el.question_internal === undefined){
        return{__html: (sectionindex + 1) +"." + (index + 1) +". " + el.question}
      }else{
        return{__html: (sectionindex + 1) +"." + (index + 1) +". " +  el.question_internal}
      }
    }
  }


  GetQuestionTitle(el,sectionindex, index,question){
    if(this.props.surveyType === "external"){
      return{__html: (sectionindex + 1) +"." + (index + 1)+"." + (question + 1) +". " + el.question}
    }else{
      if( el.question_internal === undefined){
        return{__html: (sectionindex + 1) +"." + (index + 1) +"." + (question + 1) +". " +el.question}
      }else{
        return{__html: (sectionindex + 1) +"." + (index + 1) +"." + (question + 1) +". " + el.question_internal}
      }
    }
  }



  render() {
    console.log(this.props.question)
let Marks = ""

if(this.props.question.steps ===5){
  Marks = { 0: "NA", 20: "1", 40: "2", 60: "3", 80: "4", 100: "5"}
}
if(this.props.question.steps ===10){
  Marks = { 0: "NA", 10: "1", 20: "2", 30: "3", 40: "4", 50: "5", 60: "6", 70: "7", 80: "8", 90: "9", 100: "10"}
}

        let Value = (this.props.input.value/this.props.question.steps) * 100

    return (
<>
<div className="large-33 xlarge-33 medium-50 tiny-100 small-100 top-space bottom-space extraQuestion">
      <h3 dangerouslySetInnerHTML={this.GetPerformanceQuestionTitle(this.props.question, this.props.sectionindex, this.props.index)}/>
<Slider min={0} defaultValue={Value} onAfterChange={this.onAfterChange}  marks={Marks} step={null}/>
</div>

    {this.props.question.followupquestions.map((el, index) => (

      <div key={index} className={this.state.showQuestions ? 'extraQuestion large-33 xlarge-33 medium-50 tiny-100 small-100 top-space bottom-space' : 'hide'}>
      <h4 dangerouslySetInnerHTML={this.GetQuestionTitle(el,this.props.sectionindex, this.props.index,index)}/>
      <Field name={this.props.question.section.section_name.replace(".","")+"."+ this.props.question.question.replace(".","")+"."+el.question.replace(".","")} component="textarea" rows="5" cols ="50"/>
      </div>
    ))}
    </>


    );
  }
}

function mapStateToProps(state) {
  return {
        surveyType:state.data.surveyType,

  };
}
export default connect(
  mapStateToProps,
  // { updateQuestion }
)(Slider_Controller);
