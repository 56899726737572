import React, { Component } from "react";
import { connect } from "react-redux";
import { getSurvey, setSurveyType, addResponse, updateResponse, loadOffline, setLocalSurveys,getLocalSurvey, loadOnline,setStages,loadSurvey, setUserID } from "../actions/index";
import Slider from "react-slick";
import Questions from "./questions";
import Menu from "./menu";
import Header from "./header"
import ChooseSurvey from "./choosesurvey"
import _ from 'underscore';
import { Link } from 'react-router-dom';

import "../css/slick.min.css";
import "../css/slick-theme.min.css";
import "../css/slide_styles.css";
import ReactMarkdown from "react-markdown"
import { Field, reduxForm, reset } from 'redux-form';
import QueryString from 'query-string'



export class InternalLanding extends Component {
  state = {
    activeSlide: 0,
    activeSlide2: 0,
    MenuOpen:false,

  };

  constructor(props) {
    super(props);
    this.ToggleMenu = this.ToggleMenu.bind(this);
        this.sendSurvey = this.sendSurvey.bind(this);
        this.getStarted = this.getStarted.bind(this)
  }

  componentDidMount() {

      console.log(this.props.location)
      this.props.setSurveyType("internal");

      const UserID = QueryString.parse(this.props.location.search);

      if(sessionStorage.getItem('userInfo') !== null){
                this.props.setUserID(JSON.parse(sessionStorage.getItem('userInfo'))._id)
      }


if(this.props.initialValues.length < 0){


      if(UserID.saveid !== undefined){
        //get previous answers
        this.props.loadOnline(UserID.saveid)
      }
    }

    this.props.getLocalSurvey();



  }
  componentDidUpdate() {
    if(this.props.survey === undefined){
    //this.props.getSurvey();
  }

  if(this.props.surveyID !== ""){
    this.props.getLocalSurvey();

  }

  }


  ToggleMenu(){
    if(this.state.MenuOpen){
          this.setState({ MenuOpen: false })
    }else{
          this.setState({ MenuOpen: true })
    }



  }

    sendSurvey(){
            window.location.href = `mailto:?subject=AECOM ${this.props.survey[0].name} survey&body=A key activity in our client listening programme is to gather actionable insights on our bids and improve the services we offer and deliver to our clients / you. %0D%0A%0D%0A Thankyou for agreeing to take this survey. It should take no more than 10 – 15 mins to complete. %0D%0A%0D%0A You can start the survey here and complete it at your lesuire. Results will be saved until you submit the survey. %0D%0A ${this.props.WebRoot}?surveyID=${this.props.surveyID}%26userID=${this.props.userID}`;

    }

    getStarted(){
              this.props.history.push({pathname:'/aecom/survey'});

    }




  GetSectionTitle(el){
    if(this.props.surveyType === "external"){
      return{__html:el.section_name}
    }else{
      if( el.External_section_name === undefined){
        return{__html: el.section_name}
      }else{
        return{__html: el.External_section_name}
      }
    }



  }


  render() {




  console.log(this.props.history)



    if(this.props.ready){
      let cardStyle = {
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),url(${this.props.strapiRoot + this.props.survey[0].image.url})`
      };
    return (
      <>
      <div className="page-wrap">
      <Header ToggleMenu={this.ToggleMenu} />
      <div className={this.props.surveyType === "internal" ? "":"hide"}>
      <Menu  ToggleMenu={this.ToggleMenu} MenuOpen={this.state.MenuOpen}  history={this.props.history} sendSurvey={this.sendSurvey}/>
      </div>
      <div className="ink-grid internal">
        <div className="column-group">
          <div className="all-60 tiny-90 small-90 push-center top-space">
          <div className="column-group">
            <div className="all-100  top-space">
              <h1>{this.props.survey[0].name}</h1>
              </div>
            </div>
            <div className="column-group">
              <div className="all-100">
<ReactMarkdown source={this.props.survey[0].intro_internal_landing} />
                </div>
              </div>
              <div className="column-group gutter ">
                <div className="all-50 align-center">
  <button onClick={this.sendSurvey} className="button">Client to complete</button>
                  </div>
                  <div className="all-50 align-center">
    <button onClick={this.getStarted} className="button">AECOM to complete</button>
                    </div>
                </div>
                {this.props.survey[0].interview_notes !== null ?(
                <div className="column-group gutter top-space push-center bottom-space">
                  <div className="all-50 top-space align-center">
    <a href={this.props.strapiRoot + this.props.survey[0].interview_notes.url} className="button" target="_blank">Download interviewer notes</a>
                    </div>
                    </div>
):("")}
          </div>
        </div>
      </div>
</div>
<footer>

<div className="ink-grid">
  <div className="column-group">

    <div className="footerLink">
      <Link to='/aecom/survey/view_all'>View all completed surveys</Link>

    </div>

    <div className="footerLink">
      <Link to='/aecom/survey/view_my'>View my completed surveys</Link>
        </div>
  </div>
</div>
</footer>

</>
    );
  }else{
    if(this.props.surveyID === ""){
      return (
        <div>
        <Header ToggleMenu={this.ToggleMenu} />
        <ChooseSurvey/>
</div>
      )
    }else{
    return (
<div>LOADING</div>
    )
  }

  }
  }
}
function mapStateToProps(state) {
  return {
    survey: state.data.survey,
    sections: state.data.sections,
    PerformQuestions:state.data.PerformQuestions,
    ready:state.data.ready,
    strapiRoot:state.data.strapiRoot,
    surveyType:state.data.surveyType,
    answers: state.form.bid,
    responseID:state.data.ResponseID,
    initialValues:state.data.initalValues,
    BidStages:state.data.BidStages,
    submitFailed: state.form.submitFailed,
    surveyID:state.data.surveyID,
    userID:state.data.userID,
    WebRoot:state.data.WebRoot



  };
}



export default connect(
  mapStateToProps,
  { getSurvey, setSurveyType, addResponse, updateResponse,setLocalSurveys,getLocalSurvey, loadOffline, loadOnline,setStages,loadSurvey,setUserID  }
)(InternalLanding)

//
// Intro = connect(
//   mapStateToProps,
//   { getSurvey, setSurveyType, addResponse, updateResponse,setLocalSurveys,getLocalSurvey, loadOffline  }
// )(Intro)
//
//
// export default reduxForm({
//     form: 'bid',
//   initialValues:
// })(Intro);
