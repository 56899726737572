import React, { Component } from "react";
import { connect } from "react-redux";
import GeneralQuestion from "./genquestions.js"
import PerformanceQuestion from "./performancequestions.js"

export class Questions extends Component {

  constructor(props) {
    super(props);

  }

  render() {



        if(this.props.questions.questions.length >0){


          return (
            <div className="ink-grid questions">
            <GeneralQuestion section_name={this.props.questions.section_name} index={this.props.index} questions={this.props.questions.questions} />
            </div>
          );


        }else{

          return (
            <div className="ink-grid questions">
          <PerformanceQuestion index={this.props.index} questions={this.props.questions.performance}/>
              </div>
        );

        }

}


}

function mapStateToProps(state) {
  return {
    survey: state.data.survey,
    sections:state.data.sections
  };
}
export default connect(
  mapStateToProps
)(Questions);
