import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../css/AECOM_logo_white.svg"
import {loadOnline} from "../actions/index";
import share from "../css/shareicon.svg"
import { withRouter } from 'react-router-dom';
import _ from 'underscore';
import CryptoJS from 'crypto-js';
const KEY = 'A1!AsWJ%xbl"0>?';
;





export class InnerResult extends Component {







  // <img className="ShareIcon" src={share} onClick={this.getShareUrl}/>
  render() {
    //

console.log(this.props.innerResults)

if(typeof this.props.innerResults === "string"){
  return(
      <div className="all-100 push-center">{this.props.innerResults}</div>
  )
}else{
    return (

<div >
{Object.entries(this.props.innerResults).map(function ([key,value]){

                console.log(key,value)
                return (
                  <span >
                  {key.replace(/_/g, ' ') !== "value" ?
                <div className="all-100 push-center"><h3>{key.replace(/_/g, ' ')}</h3>{value}</div>
              : <div className="all-100 push-center">{value}</div>}

                  </span>
                );
              })}
</div>
)
}

}
}

function mapStateToProps(state) {
  return {
    survey: state.data.survey,
    localSurveys:state.data.localSurveys,
    strapiRoot:state.data.strapiRoot,
    WebRoot:state.data.WebRoot,
    surveyID:state.data.surveyID,
        userID:state.data.userID,
  };
}



export default connect(
  mapStateToProps,
  {loadOnline}
)(InnerResult);
