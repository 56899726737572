import React, { Component } from "react";
import { connect } from "react-redux";
import GeneralQuestion from "./genquestions.js"
import CheckQType from "./checkQuestionType.js"
import _ from 'underscore';


export class Questions extends Component {

  constructor(props) {
    super(props);

  }

  componentDidUpdate(){

  }

  GetQuestionTitle(el,index){
    if(this.props.surveyType === "external"){
      return{__html:(this.props.index + 1) +"." + (index + 1) + ". " + el.Gen_Question}
    }else{
      if( el.Gen_question_internal === undefined){
        return{__html:(this.props.index + 1) +"." + (index + 1) + ". " + el.Gen_Question}
      }else{
        return{__html: (this.props.index + 1) +"." + (index + 1) + ". " + el.Gen_question_internal}
      }
    }
  }



  render() {

    let SetUPQ =[]
    let GeneralQ=[]

    for(let question of this.props.questions){
      if(question.question_type === "text"){
        SetUPQ.push(question)
      }else{
        GeneralQ.push(question)
      }
    }
    for(let question of SetUPQ){

// if(question.question.contains("name")){
//
// }
    }

    SetUPQ = SetUPQ.concat(GeneralQ);






return(
  <div className="column-group gutters">
  {SetUPQ.map((el, index) => (
    <div key={index} className="question top-space large-50 xlarge-33 medium-100 small-100 tiny-100 push-left align-left bottom-space">
  <h3 dangerouslySetInnerHTML={this.GetQuestionTitle(el, index)}/>


<CheckQType section_name={this.props.section_name} question={el}/>

</div>
))}
  </div>
)



  }
}

function mapStateToProps(state) {
  return {
    survey: state.data.survey,
    sections:state.data.sections,
    surveyType:state.data.surveyType
  };
}
export default connect(
  mapStateToProps
)(Questions);
