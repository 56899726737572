import React, { Component } from "react";
import { connect } from "react-redux";
import { getSurvey, setSurveyType, addResponse, updateResponse, loadOffline, setLocalSurveys,getLocalSurvey, loadOnline,setStages,loadSurvey, setUserID } from "../actions/index";
import Slider from "react-slick";
import Questions from "./questions";
import Menu from "./menu";
import Header from "./header"
import ChooseSurvey from "./choosesurvey"
import _ from 'underscore';

import "../css/slick.min.css";
import "../css/slick-theme.min.css";
import "../css/slide_styles.css";
import ReactMarkdown from "react-markdown"
import { Field, reduxForm, reset } from 'redux-form';
import QueryString from 'query-string'



export class Intro extends Component {
  state = {
    activeSlide: 0,
    activeSlide2: 0,
    MenuOpen:false,
    background:true

  };

  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.ToggleMenu = this.ToggleMenu.bind(this);
    this.sendSurvey = this.sendSurvey.bind(this);




  }

  componentDidMount() {

      console.log(this.props.location)
      this.props.setSurveyType("internal");

      const UserID = QueryString.parse(this.props.location.search);
      if(UserID.surveyID !== undefined){
        //get previous answers
        // this.props.loadSurvey(UserID.surveyID)
      }
      if(sessionStorage.getItem('userInfo') !== null){
                this.props.setUserID(JSON.parse(sessionStorage.getItem('userInfo'))._id)
      }


if(this.props.initialValues.length < 0){


      if(UserID.saveid !== undefined){
        //get previous answers
        this.props.loadOnline(UserID.saveid)
      }
    }

    this.props.getLocalSurvey();



  }
  componentDidUpdate() {
    console.log(this.props.survey)
    if(this.props.survey === undefined){
    //this.props.getSurvey();
  }

  if(this.props.surveyID !== ""){
    this.props.getLocalSurvey();

  }

  }


  ToggleMenu(){
    if(this.state.MenuOpen){
          this.setState({ MenuOpen: false })
    }else{
          this.setState({ MenuOpen: true })
    }



  }

    sendSurvey(){
            window.location.href = `mailto:?subject=AECOM ${this.props.survey[0].name} survey&body=A key activity in our client listening programme is to gather actionable insights on our bids and improve the services we offer and deliver to our clients / you. %0D%0A%0D%0A Thankyou for agreeing to take this survey. It should take no more than 10 – 15 mins to complete. %0D%0A%0D%0A You can start the survey here and complete it at your lesuire. Results will be saved until you submit the survey. %0D%0A ${this.props.WebRoot}?surveyID=${this.props.surveyID}%26userID=${this.props.userID}`;

    }




  play() {
    this.slider.slickPlay();

  }
  pause() {
    this.slider.slickPause();

  }


  next(){

console.log(this.state.activeSlide)


    this.slider.slickNext()
    this.slider.forceUpdate()
    let stages = [];
    if(this.props.answers.values.Your_Role !== undefined){
        if(this.props.answers.values.Your_Role.What_stages_did_you_evaluate !== undefined){
          for (let [key, value] of Object.entries(this.props.answers.values.Your_Role.What_stages_did_you_evaluate)){
            if(value === true){
              stages.push(key)
            }
          }
    this.props.setStages(stages)
         }
        }
            this.saveResponse(false)

            var scrollStep = -window.scrollY / (20),
                  scrollInterval = setInterval(function(){
                  if ( window.scrollY != 0 ) {
                      window.scrollBy( 0, scrollStep );
                  }
                  else clearInterval(scrollInterval);
              },15);
  }
  previous(){
    this.slider.slickPrev()
    this.saveResponse(false)
    var scrollStep = -window.scrollY / (20),
          scrollInterval = setInterval(function(){
          if ( window.scrollY != 0 ) {
              window.scrollBy( 0, scrollStep );
          }
          else clearInterval(scrollInterval);
      },15);
  }


  submit(formProps,dispatch) {
    this.props.updateResponse(this.props.responseID,this.props.answers.values, true)
    dispatch(reset('bid'));
    this.slider.slickGoTo(0)
  }


saveResponse(completed){
  //save to server
  console.log("ADDREPSONSE")
  if(this.props.responseID === ""){
  this.props.addResponse(this.props.answers.values,completed)

}else{

  let YourSurveys = []
  if(JSON.parse(localStorage.getItem('AECOMSurvey'+this.props.surveyID)) !== null){

    YourSurveys = JSON.parse(localStorage.getItem('AECOMSurvey'+this.props.surveyID))
  }

      if(!_.includes(_.flatten(YourSurveys),this.props.responseID)){

        let now = new Date();
        let Survey = []
        Survey.push(this.props.responseID,now)
        YourSurveys.push(Survey)
        this.props.setLocalSurveys(YourSurveys)
      }

      //if complted remove from my surveys might need to do on load as well

    this.props.updateResponse(this.props.responseID , this.props.answers.values, completed)
}

}


  GetSectionTitle(el, index){
    if(this.props.surveyType === "external"){
      return{__html:(index + 1) +". " + el.section_name}
    }else{
      if( el.External_section_name === undefined){
        return{__html: (index + 1) +". " + el.section_name}
      }else{
        return{__html: (index + 1) +". " + el.External_section_name}
      }
    }



  }


  render() {




  console.log(this.props.history)

    let DisplaySections = []
    for(let section of this.props.sections){
      let StageName = ""

      if(section.stage !== undefined){
        if(Array.isArray(section.stage) ){
          if(section.stage.length > 0){
            for(let stage of section.stage){
                for(let bidstage of this.props.BidStages){
                  StageName = stage.replace(" ","").replace("/","")
                  console.log(StageName, bidstage)
                  if(StageName === bidstage){
                    DisplaySections.push(section)
                  }
                }
            }
          }
          else{
                    DisplaySections.push(section)
              }
        }

      }

}

        const { handleSubmit } = this.props;
        let activeSlide =false
        //


        const settings = {
          dots: true,
          draggable: false,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          fade:false,
          adaptiveHeight:true,
          beforeChange: (current, next) => {
                   console.log("CHANGE")
                   if (current === 0) {
                     this.setState({ background: false });
                   }
                      this.setState({activeSlide:next})

               }
        };

        let ActiveSlide = this.state.activeSlide2

    if(this.props.ready){
      let cardStyle = {
        background: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),url(${this.props.strapiRoot + this.props.survey[0].image.url})`,
        position: "absolute",
        top: "0",
        left: "0",
        width: "100vw",
height: "100vh",
backgroundRepeat: "no-repeat",
backgroundSize: "cover",
backgroundPosition: "center"
      };
    return (
      <>
      <div className="page-wrap">
      <div className={this.state.background ? "bgImage":"hide"} style={cardStyle}></div>
      <Header ToggleMenu={this.ToggleMenu} />
      <div className={this.props.surveyType === "internal" ? "":"hide"}>
      <Menu  ToggleMenu={this.ToggleMenu} MenuOpen={this.state.MenuOpen}  history={this.props.history} sendSurvey={this.sendSurvey}/>
      </div>
      <form onSubmit={handleSubmit(this.submit.bind(this))} >
      <Slider ref={slider => (this.slider = slider)} {...settings}>

  <div key="0" className="FOI_slide">

    <div className="slideinner">


    <div className="all-80 small-100 tiny-100  push-center content top-space">
    <div className="push-center all-100">
    <div className="grey-text-box">
      <h1>{this.props.survey[0].name}</h1>
      <div>
    {this.props.surveyType === "internal" ?
      ( <ReactMarkdown source={this.props.survey[0].intro_internal} />):(<ReactMarkdown source={this.props.survey[0].intro} />)
    }



  </div>
</div>

</div>
</div>
</div>
</div>
        {DisplaySections.map((el, index) => (

        <div key={index} className="FOI_slide">
          <div className="slideinner">
          <div className="column-group header all-100">
          <div className="column-group all-100">
          <h2 dangerouslySetInnerHTML={this.GetSectionTitle(el,index)}/>
          </div>
          <div className="column-group all-100">
          {this.props.surveyType === "internal" ?
            ( <div className="section_info"><ReactMarkdown source={el.section_info_internal} /></div>):(<span></span>)
          }
</div>
          </div>
            <Questions questions={el} index={index} />
          </div>
        </div>



      ))}
      <div key="end" className="FOI_slide">
        <div className="slideinner">

        <div className="all-80 small-100 tiny-100 push-center content top-space">
        <div className="push-center all-100">
        <div className="grey-text-box">
      <h1>Thank you</h1>
      <div>
      {this.props.surveyType === "internal" ?
        ( <ReactMarkdown source={this.props.survey[0].thankyou_internal} />):(<ReactMarkdown source={this.props.survey[0].thankyou} />)
      }
      {this.props.submitFailed ?
        ( <div>There are some errors in your form, please correct</div>):(<span></span>)
      }
      <button className="buttonOrange">Save completed survey</button>
      </div>
      </div>
      </div>
      </div>

      </div>
      </div>



</Slider>
</form>
</div>
<footer>

<div>
<button  className={this.state.activeSlide === 0 ? 'hide' : 'button left'} onClick={this.previous}>
  Previous
</button>
<div className="slick-dots"/>
  <button className="button right" onClick={this.next}>
    Next
  </button>

</div>
</footer>


</>
    );
  }else{
    if(this.props.surveyID === ""){
      return (
        <div>
        <Header ToggleMenu={this.ToggleMenu} />
        <ChooseSurvey/>
</div>
      )
    }else{
    return (
<div>LOADING</div>
    )
  }

  }
  }
}
function mapStateToProps(state) {
  return {
    survey: state.data.survey,
    sections: state.data.sections,
    PerformQuestions:state.data.PerformQuestions,
    ready:state.data.ready,
    strapiRoot:state.data.strapiRoot,
    surveyType:state.data.surveyType,
    answers: state.form.bid,
    responseID:state.data.ResponseID,
    initialValues:state.data.initalValues,
    BidStages:state.data.BidStages,
    submitFailed: state.form.submitFailed,
    surveyID:state.data.surveyID,
    userID:state.data.userID,
    WebRoot:state.data.WebRoot



  };
}

function validate(form) {
  console.log("VALIDATE")
const errors = {};
if (!form.firstName) {
  errors.firstName = "required";
}

return errors;
}


export default connect(
  mapStateToProps,
  { getSurvey, setSurveyType, addResponse, updateResponse,setLocalSurveys,getLocalSurvey, loadOffline, loadOnline,setStages,loadSurvey,setUserID  }
)(reduxForm({
   form: 'bid', // a unique identifier for this form
  enableReinitialize: true
})(Intro))

//
// Intro = connect(
//   mapStateToProps,
//   { getSurvey, setSurveyType, addResponse, updateResponse,setLocalSurveys,getLocalSurvey, loadOffline  }
// )(Intro)
//
//
// export default reduxForm({
//     form: 'bid',
//   initialValues:
// })(Intro);
