import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../css/AECOM_logo_white.svg"
import {loadOnline} from "../actions/index";
import share from "../css/shareicon.svg"
import { withRouter } from 'react-router-dom';
import _ from 'underscore';

import FullResult from './fullresult'







export class Result extends Component {

  state = {
      fullresult:false
  };

  constructor(props) {
    super(props);
        this.getShareUrl = this.getShareUrl.bind(this);
        this.LoadSurvey = this.LoadSurvey.bind(this);
                this.viewFull = this.viewFull.bind(this);
                  this.closeFull = this.closeFull.bind(this);

  }

  componentDidMount(){


  }
  getShareUrl(el){
      this.props.ToggleMenu()
     window.location.href = `mailto:?subject=${this.props.survey[0].name} Survey to complete&body=Here is the link to complete the survey ${this.props.WebRoot}aecom?surveyID=${this.props.surveyID}%26saveid=${this.props.Survey[0]}`;
  }

  LoadSurvey(el){
    this.props.ToggleMenu()
    this.props.loadOnline(this.props.Survey[0])
    this.props.history.push({pathname:'/aecom/survey'});


  }

  GetDate(date){
    var date = new Date(date);
    var dd = date.getDate();
        var mm = date.getMonth()+1;
        var yyyy = date.getFullYear();

              if(dd<10)
              {
                  dd='0'+dd;
              }

              if(mm<10)
              {
                  mm='0'+mm;
              }

              return dd+'-'+mm+'-'+yyyy;
  }
  MySurvey(userID){
    console.log(userID, this.props.userID)
    if(userID === this.props.userID){
      return "X"
    }

  }

  viewFull(){
    this.setState({fullresult:true})
  }
  closeFull(){
    this.setState({fullresult:false})
  }

  // <img className="ShareIcon" src={share} onClick={this.getShareUrl}/>
  render() {



if(this.state.fullresult){
  return(<FullResult result={this.props.result} closeFull={this.closeFull}/>)
}else{


    return (

<div className="column-group results_item" onClick={this.viewFull}>

<div className="all-10 push-left">{this.props.result.client}</div>
<div className="all-10 push-center">{this.props.result.responses.Your_details.First_name + " "+ this.props.result.responses.Your_details.Surname}</div>
<div className="all-10 push-center">{this.GetDate(this.props.result.updatedAt)}</div>
<div className="all-10 push-center">{this.MySurvey(this.props.result.userID)}</div>
</div>
    );
  }
  }
}

function mapStateToProps(state) {
  return {
    survey: state.data.survey,
    localSurveys:state.data.localSurveys,
    strapiRoot:state.data.strapiRoot,
    WebRoot:state.data.WebRoot,
    surveyID:state.data.surveyID,
        userID:state.data.userID,
  };
}



export default connect(
  mapStateToProps,
  {loadOnline}
)(Result);
